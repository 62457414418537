<template>
    <div class="section">
    
        <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
            <div class="card popupDialog">
                <div class="card-content">
                        <iframe id="pdf" class="pdfiFrame">
                            Your browser doesn't support iframes
                        </iframe> 
                </div>
            </div>
        </b-modal>        
    
    </div>
    </template>
    
    <script>
    
    import { PDFDocument, StandardFonts, PageSizes, degrees, grayscale, rgb } from 'pdf-lib'
    import moment from 'moment'
    import UserStore from "../../../store/UserStore"
    
    export default {
        props: {
            isShowPDFDialog: Boolean,
            sortedTankRecords: Array,
            sortedSensorRecords: Array,
            dateRange: Array,
        },
    
        data() {
            return {
                pdfDoc: {},
                helveticaFont: {},
                pageNumber: 0,
                page: {},
                isShowPDFDialogLocal: false,
                settingsValues: UserStore.getters.getStoreSettingsValues,
            }
    
        },
    
        methods: {
    
            closePDFDialog() {
                this.$emit('close-pdf-dialog')
            },
    
            createPdf: async(gthis) => {
    
                gthis.pdfDoc = await PDFDocument.create()

                gthis.pdfDoc.setAuthor('VEECLi Inc. All rights reserved.')
                gthis.pdfDoc.setTitle('Leak Detection Report')
                gthis.pdfDoc.setSubject('support@veecli.com')
                gthis.pdfDoc.setCreator('VEECLi Inc - www.veecli.com')
                gthis.pdfDoc.setProducer('VEECLi Backoffice Software')
    
                gthis.helveticaFont = await gthis.pdfDoc.embedFont(StandardFonts.Helvetica)
    
                await gthis.pageHeader(gthis)
                var row = 710
    
    
                // Tank Release Detection Results
                if (gthis.sortedTankRecords.length > 0) {
                    row = await gthis.getNextRow(gthis,row, 6)
                    gthis.drawRowHeadTextLeft(gthis, row, 50, 12, 'Tank Release Detection Results')
                    gthis.drawTotalRectangle(gthis, row, 50, 540, 16, 0.1)

                    row = await gthis.getNextRow(gthis,row,2)
                    row = await gthis.drawRowTextLeft(gthis, row, "Test Date")
                    gthis.drawTextLeft(gthis, row, 100,10, "Tank#")
                    gthis.drawTextLeft(gthis, row, 150,10, "Tank")
                    gthis.drawTextLeft(gthis, row, 250,10, "Product")
                    gthis.drawTextLeft(gthis, row, 375,10, "Type")
                    gthis.drawTextLeft(gthis, row, 500,10, "Result")
                    row = await gthis.drawLine(gthis, row)

                    row = await gthis.getNextRow(gthis,row)
                    for (const tank of gthis.sortedTankRecords) {
                        row = await gthis.drawRowTextLeft(gthis, row, moment(tank.date).format('MM/DD/YYYY'))
                        gthis.drawTextLeft(gthis, row, 100,9, tank.number.toString())
                        gthis.drawTextLeft(gthis, row, 150,9, tank.name)
                        gthis.drawTextLeft(gthis, row, 250,9, tank.product)
                        gthis.drawTextLeft(gthis, row, 375,9, tank.type)
                        gthis.drawTextLeft(gthis, row, 500,9, tank.result)
                        // gthis.drawTextRightCurrency(gthis, row, 500, 10, dept.netSalesValue)
                        row = await gthis.drawLine(gthis, row)
                    }
                    // row = await gthis.getNextRow(gthis,row, 3)
                    // row = await gthis.drawRowTextLeft(gthis, row, "*** No Tank Release Detection Results Received")
                }
    
    
                // Line Release Detection Results
                if (gthis.sortedTankRecords.length > 0) {
                    row = await gthis.getNextRow(gthis,row, 6)
                    gthis.drawRowHeadTextLeft(gthis, row, 50, 12, 'Line Release Detection Results')
                    gthis.drawTotalRectangle(gthis, row, 50, 540, 16, 0.1)

                    row = await gthis.getNextRow(gthis,row,2)
                    row = await gthis.drawRowTextLeft(gthis, row, "Test Date")
                    gthis.drawTextLeft(gthis, row, 100,10, "Tank#")
                    gthis.drawTextLeft(gthis, row, 150,10, "Tank")
                    gthis.drawTextLeft(gthis, row, 250,10, "Product")
                    gthis.drawTextLeft(gthis, row, 375,10, "Type")
                    gthis.drawTextLeft(gthis, row, 500,10, "Result")
                    row = await gthis.drawLine(gthis, row)

                    row = await gthis.getNextRow(gthis,row)
                    for (const tank of gthis.sortedTankRecords) {
                        row = await gthis.drawRowTextLeft(gthis, row, moment(tank.date).format('MM/DD/YYYY'))
                        gthis.drawTextLeft(gthis, row, 100,9, tank.number.toString())
                        gthis.drawTextLeft(gthis, row, 150,9, tank.name)
                        gthis.drawTextLeft(gthis, row, 250,9, tank.product)
                        gthis.drawTextLeft(gthis, row, 375,9, tank.type)
                        gthis.drawTextLeft(gthis, row, 500,9, tank.result)
                        // gthis.drawTextRightCurrency(gthis, row, 500, 10, dept.netSalesValue)
                        row = await gthis.drawLine(gthis, row)
                    }
                    // row = await gthis.getNextRow(gthis,row, 3)
                    // row = await gthis.drawRowTextLeft(gthis, row, "*** No Line Release Detection Results Received")
                }
                
                // Sensor Status
                row = await gthis.getNextRow(gthis,row, 6)
                gthis.drawRowHeadTextLeft(gthis, row, 50, 12, 'Sensor Status')
                gthis.drawTotalRectangle(gthis, row, 50, 540, 16, 0.1)
                row = await gthis.getNextRow(gthis,row,2)
                row = await gthis.drawRowTextLeft(gthis, row, "Test Date")
                gthis.drawTextLeft(gthis, row, 100,10, "#")
                gthis.drawTextLeft(gthis, row, 125,10, "Location")
                gthis.drawTextLeft(gthis, row, 240,10, "Type")
                gthis.drawTextLeft(gthis, row, 380,10, "Sub Type")
                gthis.drawTextLeft(gthis, row, 450,10, "Category")
                gthis.drawTextLeft(gthis, row, 550,10, "Result")
                row = await gthis.drawLine(gthis, row)

                row = await gthis.getNextRow(gthis,row)
                for (const sensor of gthis.sortedSensorRecords) {
                    row = await gthis.drawRowTextLeft(gthis, row, moment(sensor.date).format('MM/DD/YYYY'))
                    gthis.drawTextLeft(gthis, row, 100,9, sensor.sensorNum.toString())
                    gthis.drawTextLeft(gthis, row, 125,9, sensor.location)
                    gthis.drawTextLeft(gthis, row, 240,9, sensor.sensorType)
                    gthis.drawTextLeft(gthis, row, 380,9, sensor.subType)
                    gthis.drawTextLeft(gthis, row, 450,9, sensor.category)
                    gthis.drawTextLeft(gthis, row, 550,9, sensor.result.replace('Sensor ',''))
                    row = await gthis.drawLine(gthis, row)
                }
                
                if (gthis.sortedSensorRecords.length < 1) {
                    row = await gthis.getNextRow(gthis,row, 3)
                    row = await gthis.drawRowTextLeft(gthis, row, "*** No Sensor Status Results Received")
                }
    
                // Create PDF Document
                const pdfDataUri = await gthis.pdfDoc.saveAsBase64({ dataUri: true });
                document.getElementById('pdf').src = pdfDataUri;
            },
    
            pageHeader: async(gthis, titleText)  => {
    
                if (!titleText) {
                    titleText = "RELEASE DETECTION REPORT"
                }
                gthis.pageNumber++
    
                gthis.page = gthis.pdfDoc.addPage(PageSizes.Letter);
                
                var storeName = UserStore.getters.getActiveStore.storeName
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(storeName, 14);
    
                gthis.page.drawText(storeName, { 
                    x: 300 - (textWidth/2), 
                    y: 750, 
                    size: 14, 
                    })
    
                var row = 725
                var reportHead = titleText
                textWidth = gthis.helveticaFont.widthOfTextAtSize(reportHead, 13);
    
                gthis.page.drawText(reportHead, { 
                    x: 300 - (textWidth/2), 
                    y: row, 
                    size: 15, 
                    })            
    
                row = 700
                var dateRange = moment(gthis.dateRange[0]).format('MM/DD/YYYY') + " - " + moment(gthis.dateRange[1]).format('MM/DD/YYYY')
                textWidth = gthis.helveticaFont.widthOfTextAtSize(dateRange, 11);
    
                gthis.page.drawText(dateRange, { 
                    x: 300 - (textWidth/2), 
                    y: row, 
                    size: 11, 
                    })
                await gthis.pageFooter(gthis)                         
                    
            },
    
            veecliLogo: async(gthis) => {
                const pngUrl = '/images/veecli-logo.png'
                const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())
    
                const pngImage = await gthis.pdfDoc.embedPng(pngImageBytes)
                const pngDims = pngImage.scale(0.08)
                
                gthis.page.drawImage(pngImage, {
                    x: 300,
                    y: 18,
                    width: pngDims.width,
                    height: pngDims.height,
                    rotate: degrees(0),
                    opacity: 0.75,
                })
                gthis.page.drawText(" VEECLi.com", { 
                    x: 308, 
                    y: 20, 
                    size: 9,
                    TextAlignment: 2
                    })                    
            },
    
            pageFooter: async(gthis) => {
    
                let timeNow = Date()
                timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')
    
                gthis.page.drawText(timeNow, { 
                    x: 20, 
                    y: 20, 
                    size: 9,
                    TextAlignment: 2
                    })
    
                var textWidth = gthis.helveticaFont.widthOfTextAtSize('Page ' + gthis.pageNumber, 9)
                gthis.page.drawText('Page ' + gthis.pageNumber, { 
                    x: 590 - textWidth, 
                    y: 20, 
                    size: 9,
                    TextAlignment: 2
                    })
                await gthis.veecliLogo(gthis)
            },
    
            getNextRow: async(gthis,row, count) => {
      
                if (!count) count = 1
                if (row > 75) {
                    return row - (5 * count)
                } else {
                    await gthis.pageHeader(gthis)
    
                    // Set Line to start records
                    return 680
                }
                
            },
    
            drawRowHeadTextLeft: async (gthis, row, colStart, fontSize, text) => {
                if (text === null) text = ""
                gthis.page.drawText(text, { 
                    x: colStart, 
                    y: row, 
                    size: fontSize, 
                })
                return row
            },
    
            drawRowTextLeft: async (gthis, row, text) => {
                if (text === null) text = ""
                row = await gthis.getNextRow(gthis,row, 2)
                // text = text[0].toUpperCase() + text.substr(1).toLowerCase()
                if (text === "InsideSales") { text = "Inside Sales"}
                if (text === "BeerWine") { text = "Beer / Wine"}
                text = gthis.toTitleCase(text)
    
                gthis.page.drawText(text, { 
                    x: 45, 
                    y: row, 
                    size: 9, 
                })
                return row
            },
    
            drawTextLeft: async (gthis, row, colStart, fontSize, text) => {
                if (text === null) text = ""
                gthis.page.drawText(text, { 
                    x: colStart, 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },
    
            drawTextRight: async (gthis, row, colStart, fontSize, text) => {
                if (text === null) text = ""
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
                gthis.page.drawText(text, { 
                    x: colStart * 1 - (textWidth), 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },
    
            drawColHeadRight:async (gthis, row, colStart, fontSize, text) => {
                if (text === null) text = ""
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
                gthis.page.drawText(text, { 
                    x: colStart * 1 - (textWidth), 
                    y: row+3, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                gthis.page.drawLine({
                    start: { x: colStart - textWidth - 40, y: row },
                    end: { x: colStart, y: row  },
                    thickness: 1,
                    opacity: 0.4,
                })
                
            },
    
            drawTextRightGal: async (gthis, row, colStart, fontSize, text) => {
                if (text === null) text = ""
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(text+" gal.", fontSize)
                gthis.page.drawText(text+" gal.", { 
                    x: colStart * 1 - (textWidth), 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },
    
            drawTextRightCurrency: async (gthis, row, colStart, fontSize, text) => {
                if (text === null) text = ""
                var textWidth = gthis.helveticaFont.widthOfTextAtSize(gthis.dollarUSLocale.format(text), fontSize)
                gthis.page.drawText(gthis.dollarUSLocale.format(text), { 
                    x: colStart * 1 - (textWidth), 
                    y: row, 
                    size: fontSize,
                    TextAlignment: 2 
                })
                
            },        
    
            drawLine: async (gthis, row) => {
                row = await gthis.getNextRow(gthis,row, 1)
                gthis.page.drawLine({
                    start: { x: 45, y: row },
                    end: { x: 580, y: row },
                    thickness: 1,
                    opacity: 0.2,
                })
                return row
            },
    
            drawTotalRectangle: async (gthis, row, colStart, width, height, opacity) => {
                gthis.page.drawRectangle({
                    x: colStart-4,
                    y: row-4,
                    width: width,
                    height: height,
                    rotate: degrees(0),
                    borderWidth: 0,
                    borderColor: grayscale(0.5),
                    color: rgb(0, 0, 0),
                    opacity: opacity,
                    borderOpacity: 0.75,
                })
            },
    
            async loadData() {
                this.isShowPDFDialogLocal = true
                this.createPdf(this)
                this.pageNumber = 0
            },
        },
      
    }
    </script>
    
    
    <style scoped>
    
    .popupDialog {
        /* position:absolute; */
        width:100%;
        height: 100%; 
        max-height: 1000px;
        min-height: 900px;     
    }
    
    .pdfiFrame {
        position:absolute; 
        top:0; 
        left:0; 
        bottom:0; 
        /* right:0;  */
        width:100%; 
        height:100%; 
        /* border:none; */
        /* margin-top: 50px; */
        /* margin-bottom: 50px; */
        /* padding:0;  */
        overflow:hidden; 
        /* z-index:999999;    */
    }
    div{
        height: 100%;
    }
    </style>